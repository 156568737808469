<template>
  <div class="field has-addons is-align-items-right">
    <div class="control is-expanded">
      <input class="input is-link" :value="doiUrl" readonly>    
    </div>
    <div class="control">
        <button class="button is-link" @click="copyToClipboard">
          <i class="fas fa-solid fa-copy"></i>
        </button>
    </div>
  </div>
</template>
  
<script lang="ts">
import Vue from "vue";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const notyf = new Notyf();

export default Vue.extend({
  props: {
    doi: {
      type: String,
      required: true,
    },
  },
  computed: {
    doiUrl() {
      return `https://doi.org/${this.doi}`;
    },
  },
  methods: {
    async copyToClipboard() {
      
      try {
        await navigator.clipboard.writeText(this.doiUrl);
        notyf.success('Copied DOI link to clipboard.');
      } catch (err) {
        notyf.error('Unable to copy to clipboard.');
      }
    },
  },
});
</script>

