<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">Project Info</p>
        </header>
        <div class="card-content">
            <p>
                Created <strong>{{ dateString }}</strong>
            </p>
            <br />
            <p>
                This project is published under the
                <b>
                    <a href="https://creativecommons.org/licenses/by/4.0/"
                        >Creative Commons Attribution 4.0 International Public
                        Licence</a
                    > </b
                >.
            </p>
            <br />
            <p>
                DOI: <strong>https://doi.org/{{project.doi}}</strong>
            </p>
            <br />
            <p>
                <small>
                    This project's unique access URI is:
                    <br />
                    <a :href="'https://bossdb.org/project/' + project.id">
                        <code>bossdb.org/project/{{ project.id }}</code>
                    </a>
                </small>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectInfoCard",
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        let dateString =  new Date(this.project.date).toLocaleDateString(
            'en-US', { year: 'numeric', month: 'long', day: 'numeric' }
        );
        return {
            dateString
        }
    }
};
</script>