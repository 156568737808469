<template>
    <button class="button is-link is-fullwidth" :title="citation" @click="copyToClipboard">
        Cite This Project
    </button>
</template>

<script lang="ts">
import Vue from "vue";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const notyf = new Notyf();
export default Vue.extend({
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    citation() {
        return `${this.project.name} [Data set]. bossdb archive. https://${this.project.doi}`;
    },
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.citation);
        notyf.success('Copied citation to clipboard.');
      } catch (err) {
        notyf.error('Unable to copy to clipboard.');
      }
    },
  },
});
</script>
