<template>
    <img
        v-tooltip="'This dataset has a derived connectome graph.'"
        :src="connectomeIcon"
        :style="`height: ${height}`"
        v-on:click="onclick()"
    />
</template>

<script lang="ts">
import Vue from "vue";
import { ASSETS_BASE_URL } from '@/assets.js';

export default Vue.extend({
    props: {
        height: {
            type: String,
            default: "2em"
        },
        onclick: {
            type: Function
        }
    },
    data() {
        return {
            connectomeIcon: ASSETS_BASE_URL + "datatypes/connectome.png" ,
        };
    },
});
</script>
