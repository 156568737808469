<template>
    <span :class="{ 'is-highlighted-border': isSelected }" class="datatype-icon">
        <img v-tooltip="`This dataset has ${label}.`" :src="icon" :style="`height: ${height}`"
            :class="{ 'icon-image': true, 'is-highlighted-img': isSelected }"
            v-on:click="onclick ? onclick() : () => { }" />
    </span>
</template>

<script lang="ts">
import Vue from "vue";
import { ASSETS_BASE_URL } from '@/assets.js';

let icon = null;
let label = null;

export default Vue.extend({
    props: {
        height: {
            type: String,
            default: "2em"
        },
        onclick: {
            type: Function
        },
        isSelected: {
            type: Boolean
        },
        dataType: {
            type: String
        }
    },
    data() {
        switch (this.dataType) {
            case "segmentation":
                icon = ASSETS_BASE_URL + "datatypes/segmentation.png";
                label = "volumetric segmentation";
                break;
            case "connectomics":
                icon = ASSETS_BASE_URL + "datatypes/connectome.png";
                label = "a derived connectome graph";
                break;
            case "mesh":
                icon = ASSETS_BASE_URL + "datatypes/mesh.png";
                label = "volumetric mesh";
                break;
            default:
                icon = ASSETS_BASE_URL + "datatypes/imagery.png";
                label = "volumetric imagery";
        }

        return {
            icon, label
        };
    },
});
</script>

<style lang="scss" scoped>
.icon-image {
    vertical-align: middle;
}

.datatype-icon {
    filter: opacity(0.5);
    cursor: pointer;
}

.is-highlighted-img {
    box-shadow: 0px 0px 0px 4px mediumaquamarine;
}

.is-highlighted-border {
    filter: opacity(1);
}
</style>
